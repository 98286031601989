export const prices = [
    {
      name: 'Price',
      min: 0,
      max: 0,
    },
    {
      name: `500 to 1000`,
      min: 500,
      max: 1000,
    },
    {
      name: `1000 to 2000`,
      min: 1000,
      max: 2000,
    },
    {
      name: `2000 to 3000`,
      min: 2000,
      max: 3000,
    },
  ];
  export const ratings = [
    {
      name: 'Rating',
      rating: 5,
    },
    {
      name: '4stars & up',
      rating: 4,
    },
  
    {
      name: '3stars & up',
      rating: 3,
    },
  
    {
      name: '2stars & up',
      rating: 2,
    },
  
    {
      name: '1stars & up',
      rating: 1,
    },

  ];

  export const shippings = [
      {
        District: "Dhaka",
        Area: [
          {
            name:"Mohammadpur(Dhaka)",
            postCode: "1207",
            charge: 70 
          },
          {
            name:"Dhanmondi - Rd 3",
            postCode: "1207",
            charge: 70 
          },
        ]
      },

      
  ];

  