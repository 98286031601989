import { useEffect } from 'react';

const ContactUsScreen = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ marginLeft: '12px', marginRight: '12px' }}>
      <h1>Contact Us</h1>

      <p>Email: khanmashrurhosain@gmail.com</p>
      <p>Phone : +01952500489, +01521330995</p>
    </div>
  );
};
export default ContactUsScreen;
