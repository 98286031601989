import { MdLocationOn } from 'react-icons/md';
const Data_Shop_Sellect_seller = [
  {
    id: 1,
    icon: <MdLocationOn />,
    ShopType: 'Physical Shop Owner',
    Tag: 'About Physical Shop',
    url: '',
  },
];

export default Data_Shop_Sellect_seller;
