export const SHOP_NAMES_REQUEST = 'SHOP_NAMES_REQUEST';
export const SHOP_NAMES_SUCCESS = 'SHOP_NAMES_SUCCESS';
export const SHOP_NAMES_FAIL = 'SHOP_NAMES_FAIL';


export const SHOP_LIST_REQUEST = 'SHOP_LIST_REQUEST';
export const SHOP_LIST_SUCCESS = 'SHOP_LIST_SUCCESS';
export const SHOP_LIST_FAIL = 'SHOP_LIST_FAIL';

export const DOKAN_LIST_REQUEST = 'DOKAN_LIST_REQUEST';
export const DOKAN_LIST_SUCCESS = 'DOKAN_LIST_SUCCESS';
export const DOKAN_LIST_FAIL = 'DOKAN_LIST_FAIL';