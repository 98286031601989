import Page1_PhnNo from './Page1_PhnNo';

const MainMarchant = () => {
  return (
    <div>
      <Page1_PhnNo />
    </div>
  );
};

export default MainMarchant;
