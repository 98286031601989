import { useEffect } from 'react';

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div style={{ marginLeft: '12px', marginRight: '12px' }}>
      <h1>Terms and Conditions</h1>
      <p>
        Welcome to Amazona.It is the marketplace where busniesses like B2B and
        B2C can occur. We offer integration of all physical and digital shops
        and continuously working on to connect buyers and sellers for mutual
        benefits. This is the first Bangladeshi platform specifically designed
        to bring all the shopping malls and their shops right at your door-
        step.Throughout the site, the terms “we”, “us” and “our” refer to
        Amazona. Before assessing the terms and conditions please notice that
        the terms and conditions may alternate anytime. Therefore, please take a
        look at the terms and conditions whenever you place an order.
      </p>

      <h2>Overview</h2>
      <p>
        You agree to these terms by using Amazona Services. Please take the time
        to read them thoroughly.
      </p>
      <ol type="1">
        <li>
          The terms and conditions apply to your access and use of Amazona&#39;s
          websites, mobile sites, mobile applications, and other portals,
          including but not limited to the web and mobile-optimized versions of
          the website, that are owned, operated, branded, or made available by
          Amazona from time to time and that are related to the Amazona
          e-commerce platform.
        </li>

        <li>
          To access the platform, we may require you to create an account with
          us or provide personal information to complete an account. We may also
          at any time in our sole and absolute discretion, invalidate the
          username and/or password without giving any reason or prior notice and
          shall not be liable or answerable for any losses suffered by, caused
          by, arising out of, in reference to or through reason of such request
          or invalidation.
        </li>

        <li>
          <ol type="a">
            <li>
              {' '}
              The product delivery time within dhaka will be maximum of 5 days
              (Depends on customer to receive the phone call). The delivery man
              will try to give the delivery by next day. If the customer does
              not answers the call, the delivery man will try to contact with
              the customer for 3 days.
            </li>
            <li>
              The product delivery time outside dhaka will be maximum of 10 days
              (Depends on customer to receive the phone call). The delivery man
              will try to give the delivery within 3 to 4 days. If the customer
              does not answers the call, the delivery man will try to contact
              with the customer for 3 days.
            </li>
          </ol>
        </li>
      </ol>
      <h2>Account policy:</h2>
      <p>
        We may require you to create an account with us or provide personal
        information to complete the creation of an account in order to access
        certain platform services. We may, without giving any reason or prior
        notice, invalidate the username and/or password at any time in our sole
        and absolute discretion, and we will not be liable or responsible for
        any losses incurred as a result of, caused by, arising out of, in
        connection with, or as a result of such request or invalidation. We may
        have a solid reason to invalidate the username and/or password but we
        are not bound to disclose the reason behind it. We may disclose these
        information for legal purpose or while co- operating with Bangladesh
        Government. You are responsible for keeping your user ID, password,
        account information, and any private information confidential. You agree
        to take on this duty and guarantee that your account and related
        information are kept secure at all times, as well as that all required
        precautions are made to avoid account misuse. If you have reason to
        believe that your password has become known to anyone else, or if the
        password is being, or is likely to be used in an unlawful manner, you
        should notify us immediately. Or take self-actions. Please be sure that
        the information you give us is accurate and comprehensive at all times.
        By accessing your account online, you are required to update details
        about your account in real time. If there are any pieces of information
        that you are unable to update through Your Account on the Site, you must
        notify us using our customer service communication lines so that we can
        aid you. Personal information/data given by you or collected through
        your use of the site will be kept absolutely secret in compliance with
        the Privacy Agreement and applicable laws and regulations. Please do not
        use the site if you do not agree to your information being transmitted
        or used in the manner described in the agreement. You agree, understand,
        and accept that the site is an online platform that allows you to
        purchase items featured at the price stated at any time from any place
        using your preferred payment method. You also agree and recognize that
        we are merely a facilitator and cannot be a party to or control in any
        way any transactions on the site or through a payment gateway provided
        by a third-party service provider. As a result, the sale of items on the
        site shall be a purely bipartite contract between you and the sellers on
        our site. With payment processing taking place between you, the service
        provider, and your issuing bank or MFC in the event of prepayments with
        electronic cards.
      </p>
      <h2>Seller policy:</h2>
      <ol type="1">
        <li>
          As this is an independent website and not working under any other
          institution, you will be considered as an individual seller and your
          Amazona shop is fully independent and will not fall under any
          restrictions or regulations of any other body except government
          purpose.
        </li>
        <li>
          Our transactions will at an interval of 5 and 10 days.Money received
          though cash on delivery will be cleared within 5 days and money
          received on online payment will cleared within 10 days.The
          transactions will be conducted through bank account or MFS service.If
          money is given through cash please reserve our provided receipt for
          future purposes.This is the regular process of the payment.If MFS
          service is choosen then service fee will be beared by the shop.If
          there is any other individual instructions please inform before
          initiating any complains/Request will be taken into consideration.
        </li>

        <li>
          Our commission always have to be included in the price mentioned with
          the product.
        </li>

        <li>
          There is a option for taking advance for any particular goods which is
          10%. The advance for any product should be clearly mentioned in the
          particular product page.When advance payment against a order is
          recived,seller will be notified.If customers denies to take the
          ordered product giving reason like change of mind,3% advance money
          will be given to seller as a damarage against holding the product.(Not
          applicable right now)
        </li>

        <li>
          The product that should be given exactly stated in the description,If
          sellers fails to provide the product exactly as the description,
          he/she must contact with the customer regarding this issue.If customer
          agrees to take the product then the product should be given for
          delivery.But if seller sends a wrong product or the product is not
          same as the description and customers doesn’t take it, then seller
          will bear the delivery cost of the product.
        </li>

        <li>
          Seller must include sell receipt of the product while giving the
          product for delivery.This recipt will be in the packageing of the
          product.return/no return ,wareenty,after sell service etc information
          should be mentioned in the receipt.
        </li>

        <li>
          It is recommended to use own shop bag while giving a product for
          delivery.When an order is recived,it is sellers duty to pack the
          product safe enough for delivery by third party team.
        </li>

        <li>Illegal products are prohibited to sell on Amazona.</li>

        <li>
          If a product is stocked out,seller must immediately remove it or
          mention stock out using provided sellers dashboard.If seller want
          Amazona to remove it or mention stock out,then it must be informed as
          soon as possible.We strictly recoomend to follow this for the
          betterment of every parties.
        </li>

        <li>
          If customer doesn’t pick up the phone and delivery man cannot reach
          the customer,the product will be returned to seller within 5 days.If
          no advance is taken from the customer seller will not get any damarage
          money.In this situation delivery fee will be beared by Amazona.
        </li>

        <li>
          If the product is found damaged while return,seller must open it
          infront of the delivery man.Then shop owner must write the condition
          of the product and take a signatuire form the delivery man as
          witness.This is very necessary for Amazona for taking action for
          damaged return product.Without this no action can be taken.
        </li>

        <li>
          Seller must include every necessary information of the product in the
          product description.If any problem occurs due to lack of information
          provided by the seller, Amazona will not be responsible.
        </li>
      </ol>
    </div>
  );
};

export default TermsAndConditions;
