export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET';

export const USER_SIGNIN_REQUEST = 'USER_SIGNIN_REQUEST';
export const USER_SIGNIN_SUCCESS = 'USER_SIGNIN_SUCCESS';
export const USER_SIGNIN_FAIL = 'USER_SIGNIN_FAIL';

export const USER_FORGETPASSWORD_REQUEST = 'USER_FORGETPASSWORD_REQUEST';
export const USER_FORGETPASSWORD_SUCCESS = 'USER_FORGETPASSWORD_SUCCESS';
export const USER_FORGETPASSWORD_FAIL = 'USER_FORGETPASSWORD_FAIL';

export const USER_SIGNOUT = 'USER_SIGNOUT';

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST';
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS';
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL';

export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST';
export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS';
export const USER_UPDATE_PROFILE_FAIL = 'USER_UPDATE_PROFILE_FAIL';
export const USER_UPDATE_PROFILE_RESET = 'USER_UPDATE_PROFILE_RESET';

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_FAIL = 'USER_LIST_FAIL';

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL';
export const USER_DELETE_RESET = 'USER_DELETE_RESET';

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL';
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET';

export const USER_TOPSELLERS_LIST_REQUEST = 'USER_TOPSELLERS_LIST_REQUEST';
export const USER_TOPSELLERS_LIST_SUCCESS = 'USER_TOPSELLERS_LIST_SUCCESS';
export const USER_TOPSELLERS_LIST_FAIL = 'USER_TOPSELLERS_LIST_FAIL';

export const USER_VERIFICATION_UPDATE_REQUEST = 'USER_VERIFICATION_UPDATE_REQUEST';
export const USER_VERIFICATION_UPDATE_SUCCESS = 'USER_VERIFICATION_UPDATE_SUCCESS';
export const USER_VERIFICATION_UPDATE_FAIL = 'USER_VERIFICATION_UPDATE_FAIL';

export const USER_DETAILS_SELLER_REQUEST = 'USER_DETAILS_SELLER_REQUEST';
export const USER_DETAILS_SELLER_SUCCESS = 'USER_DETAILS_SELLER_SUCCESS';
export const USER_DETAILS_SELLER_FAIL = 'USER_DETAILS_SELLER_FAIL';


export const USER_DETAILS_WHOLE_SELLER_REQUEST = 'USER_DETAILS_WHOLE_SELLER_REQUEST';
export const USER_DETAILS_WHOLE_SELLER_SUCCESS = 'USER_DETAILS_WHOLE_SELLER_SUCCESS';
export const USER_DETAILS_WHOLE_SELLER_FAIL = 'USER_DETAILS_WHOLE_SELLER_FAIL';

export const USER_DETAILS_HELLO_MALL_SELLER_REQUEST = 'USER_DETAILS_HELLO_MALL_SELLER_REQUEST';
export const USER_DETAILS_HELLO_MALL_SELLER_SUCCESS = 'USER_DETAILS_HELLO_MALL_SELLER_SUCCESS';
export const USER_DETAILS_HELLO_MALL_SELLER_FAIL = 'USER_DETAILS_HELLO_MALL_SELLER_FAIL';

export const USER_FORGET_PASSWORD_REQUEST = 'USER_FORGET_PASSWORD_REQUEST';
export const USER_FORGET_PASSWORD_SUCCESS = 'USER_FORGET_PASSWORD_SUCCESS';
export const USER_FORGET_PASSWORD_FAIL = 'USER_FORGET_PASSWORD_FAIL';

export const USER_NEW_PASSWORD_REQUEST = 'USER_NEW_PASSWORD_REQUEST';
export const USER_NEW_PASSWORD_SUCCESS = 'USER_NEW_PASSWORD_SUCCESS';
export const USER_NEW_PASSWORD_FAIL = 'USER_NEW_PASSWORD_FAIL';

export const USER_VERIFICATION_CODE_FORGET_PASSWORD_REQUEST = 'USER_VERIFICATION_CODE_FORGET_PASSWORD_REQUEST';
export const USER_VERIFICATION_CODE_FORGET_PASSWORD_SUCCESS = 'USER_VERIFICATION_CODE_FORGET_PASSWORD_SUCCESS';
export const USER_VERIFICATION_CODE_FORGET_PASSWORD_FAIL = 'USER_VERIFICATION_CODE_FORGET_PASSWORD_FAIL';